
  import { Component, Vue, Prop, Emit } from "vue-property-decorator";
  import { formatDate } from "@/utils/formatters";

  @Component({
    components: {
      ElCard: () => import("@/views/seller/parts/general/el-card.vue"),
    },
  })
  export default class PageSellerBidCard extends Vue {
    @Prop({ required: true }) bid!: Bid;

    @Prop({ default: false }) detail!: boolean;

    @Prop() tag?: string;

    formatDate = formatDate;

    get person() {
      if (!this.bid.representatives[0]) {
        return;
      }

      if (!this.bid.representatives[0].title || this.bid.representatives[0].title === "") {
        return this.bid.representatives[0].last_name;
      }

      return this.$t(`representative.enums.title.${this.bid.representatives[0]!.title}`) + " " + this.bid.representatives[0].last_name;
    }

    get price() {
      return this.bid.amount.toLocaleString("nl-BE", { minimumFractionDigits: 2, currency: "EUR", currencySign: "accounting", style: "currency" });
    }

    @Emit("click")
    handleClick() {
      return this.bid;
    }
  }
